<template>
    <div>
        <div class="bg">
            <img src="../assets/imgs/uploads/bgfaq.jpg" alt="" style="width: 100%;">
            <div class="search">
                <div
                    style="font-size: 48px;font-weight: bold;color: #FFF;display: flex;justify-content: center;margin-bottom: 31px;">
                    Search for your question</div>
                <div style="position: relative;">
                    <el-input v-model="searchQuery" @keyup.enter.native="searchText"
                        placeholder="Enter your search term here..."></el-input>
                    <div class="shbtn" @click="searchText">
                        <img src="../assets/imgs/uploads/mynaui_search.png" style="width: 26px;height: 26px;" alt="">
                    </div>
                </div>
                <div class="add">
                    <img src="../assets/imgs/uploads/Group 3911.png"
                        style="width: 16px;height: 16px;background-color: #CA141D;" alt="">
                    <span style="font-size: 16px;color: #FFF;margin-left: 9px;" @click="taggle">Help & Contact us</span>
                </div>
            </div>
        </div>
        <div v-if="status == '1'" class="wrps">
            <el-collapse v-model="activeNames1">
                <el-collapse-item name="1">
                    <template slot="title">
                        <img src="../assets/imgs/uploads/mingcute_question-fill.png" style="width: 30px;height: 30px;"
                            alt="">
                        <div style="margin-left: 15px;">Gernerals </div>
                    </template>
                    <div style="margin: 48px 52px 0;">
                        <div v-html="highlightText(content1)" class="ques">
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item name="2">
                    <template slot="title">
                        <img src="../assets/imgs/uploads/fa-solid_shipping-fast.png" style="width: 30px;height: 24px;"
                            alt="">
                        <div style="margin-left: 15px;">Products </div>
                    </template>
                    <div style="margin: 48px 52px 0;">
                        <div v-html="highlightText(content2)" class="ques">
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item name="3">
                    <template slot="title">
                        <img src="../assets/imgs/uploads/fluent_payment-20-filled.png" style="width: 30px;height: 30px;"
                            alt="">
                        <div style="margin-left: 15px;">Order/Payment/Transportation </div>
                    </template>
                    <div style="margin: 48px 52px 0;">
                        <div v-html="highlightText(content3)" class="ques">
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item name="4">
                    <template slot="title">
                        <img src="../assets/imgs/uploads/ep_promotion.png" style="width: 30px;height: 24px;" alt="">
                        <div style="margin-left: 15px;">After-sales service </div>
                    </template>
                    <div style="margin: 48px 52px 0;">
                        <div v-html="highlightText(content4)" class="ques">
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div v-else class="help">
            <div style="margin-top: 54px;font-weight: bold;font-size: 20px;">Help & Contact us</div>
            <p style="margin-top: 19px;">Still can't find what you're looking for? Please fill out the form below and we’ll
                get back to you right away!</p>
            <p>Or contact customer care at 0086 400-870-1705.</p>
            <el-form style="margin-top: 30px;" :model="ValidateForm" ref="ValidateForm" label-width="130px"
                class="demo-ruleForm">
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item label="Your email" prop="email" :rules="[
                        { required: true, message: 'please enter your Email', trigger: 'blur' },
                        { type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change'] }
                    ]">
                        <el-input v-model="ValidateForm.email" placeholder="Enter your email" autocomplete="off"
                            class="two"></el-input>
                    </el-form-item>
                    <el-form-item label="Your name" prop="name" :rules="[
                        { required: true, message: 'name cannot be left blank.' },
                    ]">
                        <el-input v-model="ValidateForm.name" placeholder="Enter your name" autocomplete="off"
                            class="two"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item label="Type" prop="type"
                        :rules="[{ required: true, message: 'Please select a type', trigger: 'change' }]">
                        <el-select class="two" v-model="ValidateForm.type" placeholder="-Please select a type">
                            <el-option label="Payment" value="payment"></el-option>
                            <el-option label="Shipping" value="shipping"></el-option>
                            <el-option label="Returns" value="returns"></el-option>
                            <el-option label="Place an order" value="place an order"></el-option>
                            <el-option label="Order Status" value="order status"></el-option>
                            <el-option label="Other enquiry" value="other enquiry"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Order number" prop="number">
                        <el-input v-model="ValidateForm.number" placeholder="Enter your Order number" autocomplete="off"
                            class="two"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="Subject" prop="subject">
                    <el-input v-model="ValidateForm.subject" autocomplete="off" class="two1"></el-input>
                </el-form-item>
                <el-form-item label="Description" prop="description" :rules="[
                    { required: true, message: 'Please provide a description.', trigger: 'blur' }]">
                    <el-input type="textarea" :rows="9" v-model="ValidateForm.description"></el-input>
                </el-form-item>
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item>
                        <el-button type="primary" class="back" @click="taggle()">Back</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="submit" @click="submitForm('ValidateForm')">Submit</el-button>
                    </el-form-item>
                </div>

            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInfo: null,
            searchQuery: '',
            status: '1',
            ValidateForm: {
                email: '',
                name: '',
                type: '',
                number: '',
                subject: '',
                description: '',
            },
            activeNames1: ['1'],
            content1: `<div>1. How can I become a customer ?</div>
                         <p>
                            We currently work with distributors in international market, if you are a private person, you can’t buy GiGwi products directly from us. 
                            to become a GiGwi distributor customer, you can ask for a log-in if you meet the right conditions. Are you interested? Then fill out this form. Once receiving,  our assigned representative will check and contact you soon. Or you can contact our info@gigwi.com.hk
                        </p>
                        <hr>
                        <div>2.What conditions do I have to meet to become a distributor ?</div>
                        <p>On the whole, we require our distributor, 
                        </p>
                        <p>· must have an established reputation and track record for the distribution of pet products.</p>
                        <p>· Shall have appropriate warehousing space and facilities to store the Products.</p>
                        <p>· Shall, at its expense, at all times store and maintain its stock of Products in its warehouse facilities sufficient to satisfy its customers’ demand.</p>
                        <p>Once submitting your application form, our sales will check per each market and come back to you asap. As for some market, we already work with distributor or exclusive distributor. </p>
                        <hr>
                        <div>3.How can I order ?</div>
                        <p>
                            After you’ve requested and received a log-in, you can log into our web shop and place your order yourself. You can also place orders by contacting your representative. 
                        </p>                       
                        <hr>
                        `,
            content2: `<div>1. Why I can not see the price on website?</div>
                        <p>At moment, we are not available to sell to end-customers directly. So if you are private person, you can not buy GiGwi products directly. That’s why we do not mention prices on our website.</p>
                        <p>For distributors, pls kindly submitted your application form, and wait for checking, only with log-in, you will be able to see the prices.</p>
                        <hr>
                        <div>2. Can I get a catalogue?</div>
                        <p>
                            Want to become a distributor? Contact us or fill out the registration form and we’ll assign one of our sales representatives to check the cooperation details with you.
                        </p>
                        <hr>
                        `,
            content3: `<div>1. Where is your warehouse located?</div>
                       <p>Our warehouse is located in Nanjing, Jiangsu, China</p>
                       <hr>
                       <div>2. How is the shipping cost?</div>
                       <p>Normally, we send offer based on EXW or FOB Shanghai, depends on the order amount. As most of our current customers has their own forwarder agent for consolidating the containers. sure, if you need us to offer based on other shipment term, like DDP, DDU, can also let us know. We can consult with our forwarder and quote you separately.</p>
                        <hr>
                        `,
            content4: `<div>1. How do I contact customer service?</div>
                       <P>If you have any problems with your order, please contact your seller directly for faster resolution.</p>
                       <P>If you purchased in a store, please contact the store.</p>
                       <p>If you purchased from a distributor, contact the distributor.</p>
                       <p>If you purchase from GiGwi headquarters, please contact us at info@gigwi.com.hk</p>
                       <hr>
                       <div>2. What is your return policy?</div>
                       <p>If there are quality problems with your order, please contact the direct seller to return or exchange for you. Feedback step by step, and finally our headquarters will replace or refund the products for quality reason.</p>
                       <hr>
                       `,
        }
    },
    activated() {
        this.status = '1'
    },
    mounted() {
        const storedUserInfo = localStorage.getItem('user');
        // 判断是否存在用户信息
        if (storedUserInfo) {
            this.userInfo = JSON.parse(storedUserInfo);
        }
    },
    methods: {
        highlightText(text) {
            if (!this.searchQuery) return text;
            const regex = new RegExp(this.searchQuery, 'gi');
            let isFirstMatch = true; // 标记第一个匹配项，以便后续滚动
            return text.replace(regex, (match) => {
                const result = `<span class="highlight123">${match}</span>`;
                if (isFirstMatch) {
                    isFirstMatch = false;
                    return `<span class="highlight123" id="first-match">${match}</span>`;
                }
                return result;
            });
        },
        searchText() {
            this.resetSearch();
            // 检查每个内容块是否包含搜索关键词
            const contents = [this.content1, this.content2, this.content3, this.content4,];
            contents.forEach((content, index) => {
                if (content.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                    this.activeNames1.push(String(index + 1));  // 添加到 activeNames 以展开对应的折叠项
                }
            });
            this.$nextTick(() => {
                setTimeout(() => {  // 添加延时以确保 DOM 更新完成
                    const element = document.getElementById('first-match');
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }, 300);
            });
        },
        resetSearch() {
            this.activeNames1 = [];  // 重置折叠状态
        },
        taggle() {
            if (this.status == '1') {
                this.status = '0'
            } else {
                this.status = '1'
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                const param = {
                    ...JSON.parse(JSON.stringify(this.ValidateForm)),
                    userId: this.userInfo ? this.userInfo.id : '',
                    userName: this.userInfo ? this.userInfo.nickName : '',
                }
                if (valid) {
                    this.$axios
                        .post("/api/mall/contact/save", param)
                        .then(res => {
                            if (res.data.code === 200) {
                                this.$message({
                                    message: 'Message sent successfully!',
                                    type: 'success'
                                });
                                this.$router.push({ path: "/finishedregister" });
                            } else {
                                this.notifyError(res.data.message);
                            }
                        })
                        .catch(err => {
                            return Promise.reject(err);
                        });
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style scoped>
.bg {
    position: relative;
}

.search {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

::v-deep .el-input__inner {
    width: 1045px;
    height: 65px;
    line-height: 65px;
}

.shbtn {
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translate(-50%, -50%);
}

.add {
    margin-top: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wrps {
    width: 1280px;
    margin: 51px auto 167px;
}

.title {
    width: 100%;
    height: 94px;
    background-color: #CA141D;
    color: #FFF;
    font-size: 36px;
    font-weight: bold;
    padding-left: 52px;
    display: flex;
    align-items: center;
    margin-bottom: 48px;
}

.ques ::v-deep div {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
}

.ques ::v-deep p {
    font-size: 16px;
}

.ques ::v-deep hr {
    color: #B4BAC1;
}

::v-deep .el-collapse-item__header {
    background-color: #CA141D;
    height: 94px;
    font-size: 36px;
    font-weight: bold;
    color: #FFF;
    padding-left: 52px;
}

::v-deep .el-collapse-item__header.focusing:focus:not(:hover) {
    color: #FFF;
}

::v-deep .el-collapse-item__arrow {
    margin: 0;
}

::v-deep .el-collapse-item__wrap {
    border: none;
}

.ques ::v-deep .highlight123 {
    background-color: yellow !important;
}

.help {
    width: 1280px;
    margin: 0 auto;
}


::v-deep .two input {
    width: 450px;
    height: 54px;
}

::v-deep .two1 input {
    width: 1150px;
    height: 54px;
}

::v-deep .two1 .el-input__inner:focus {
    border-color: #CA141D !important;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #CA141D;
}

::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
    background-color: #CA141D !important;
    color: #FFF !important;
}

::v-deep .el-form-item__label {
    text-align: left;
}

.el-select-dropdown__item.selected {
    color: #000;
}

::v-deep .el-textarea__inner:focus {
    border-color: #000;
}

::v-deep .el-textarea__inner {
    resize: none;
}

.back {
    width: 151px;
    height: 42px;
    background-color: #565656;
    border: #565656;
}

.submit {
    width: 151px;
    height: 42px;
}
</style>